/**
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { assert } from './assert';

// Code originally came from goog.crypt.stringToUtf8ByteArray, but for some reason they
// automatically replaced '\r\n' with '\n', and they didn't handle surrogate pairs,
// so it's been modified.

// Note that not all Unicode characters appear as single characters in JavaScript strings.
// fromCharCode returns the UTF-16 encoding of a character - so some Unicode characters
// use 2 characters in Javascript. All 4-byte UTF-8 characters begin with a first
// character in the range 0xD800 - 0xDBFF (the first character of a so-called surrogate
// pair).
// See http://www.ecma-international.org/ecma-262/5.1/#sec-15.1.3

/**
 * @param {string} str
 * @return {Array}
 */
export const stringToByteArray = function(str) {
 var out = [],
 p = 0;
 for (var i = 0; i < str.length; i++) {
 var c = str.charCodeAt(i);

 // Is this the lead surrogate in a surrogate pair?
 if (c >= 0xd800 && c <= 0xdbff) {
 var high = c - 0xd800; // the high 10 bits.
 i++;
 assert(i < str.length, 'Surrogate pair missing trail surrogate.');
 var low = str.charCodeAt(i) - 0xdc00; // the low 10 bits.
 c = 0x10000 + (high << 10) + low;
 }

 if (c < 128) {
 out[p++] = c;
 } else if (c < 2048) {
 out[p++] = (c >> 6) | 192;
 out[p++] = (c & 63) | 128;
 } else if (c < 65536) {
 out[p++] = (c >> 12) | 224;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 } else {
 out[p++] = (c >> 18) | 240;
 out[p++] = ((c >> 12) & 63) | 128;
 out[p++] = ((c >> 6) & 63) | 128;
 out[p++] = (c & 63) | 128;
 }
 }
 return out;
};

/**
 * Calculate length without actually converting; useful for doing cheaper validation.
 * @param {string} str
 * @return {number}
 */
export const stringLength = function(str) {
 var p = 0;
 for (var i = 0; i < str.length; i++) {
 var c = str.charCodeAt(i);
 if (c < 128) {
 p++;
 } else if (c < 2048) {
 p += 2;
 } else if (c >= 0xd800 && c <= 0xdbff) {
 // Lead surrogate of a surrogate pair. The pair together will take 4 bytes to represent.
 p += 4;
 i++; // skip trail surrogate.
 } else {
 p += 3;
 }
 }
 return p;
};
